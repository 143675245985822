import {
  Banner,
  Box,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Container,
  Heading,
  Helmet,
  IconCallCenterAgent,
  IconCarService,
  IconGrid,
  IconHealthInsurance,
  IconPin,
  IconRespite,
  IconSalary,
  IconTraining,
  IconTravel,
  IconTrophy,
  Layout,
  Link,
  NavigationTiles,
  Panel,
  PreStyledComponents,
  ReadMoreLink,
  RelatedPagesContent,
  Row,
  Section,
  SocialMediaLinks,
  StandardButton,
  Theme,
  Tile,
  YouTubeVideo,
  routesObject
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"

import React from "react"
import { graphql } from "gatsby"

const {
  OurValues,
  DiversityAndInclusion,
  OurLGBTIJourney,
  WePutChildrenFirst,
  ReconciliationActionPlanNoIcon,
  OurAIEP
} = RelatedPagesContent

const { IconWrapper } = PreStyledComponents
const { corporateThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    diversityAnod: IGatsbyImageData
    eeonMember: IGatsbyImageData
  }
}

const SearchOpportunitiesButton = () => (
  <Link
    className="no-underline"
    to="https://careers.lwb.org.au/en/listing/"
    rooted
  >
    <StandardButton shade="dark" className="center w-100 w-auto-ns">
      Search Opportunities
    </StandardButton>
  </Link>
)

const employeePerks = [
  {
    icon: "salary",
    title: "Salary packaging",
    iconHeight: "50"
  },
  {
    icon: "healthinsurance",
    title: "Discounted health insurance"
  },
  {
    icon: "carservice",
    title: "Discounted vehicle services",
    iconHeight: "50"
  },
  {
    icon: "callCenterAgent",
    title: "Employee Assistance Program"
  },
  {
    icon: "trophy",
    title: "Employee recognition awards"
  },
  {
    icon: "training",
    title: "Internal and external training",
    iconHeight: "40"
  },
  {
    icon: "respite",
    title: "Rostered day off",
    description: "(role dependent)"
  },
  {
    icon: "travel",
    title: "Travel club membership",
    iconHeight: "47"
  }
]

const iconSelector = (type: string, color: string, height?: string) => {
  if (type === "salary") {
    return <IconSalary color={color} height={height} />
  }
  if (type === "healthinsurance") {
    return <IconHealthInsurance color={color} height={height} />
  }
  if (type === "carservice") {
    return <IconCarService color={color} height={height} />
  }
  if (type === "callCenterAgent") {
    return <IconCallCenterAgent color={color} height={height} />
  }
  if (type === "trophy") {
    return <IconTrophy color={color} height={height} />
  }
  if (type === "training") {
    return <IconTraining color={color} height={height} />
  }
  if (type === "respite") {
    return <IconRespite color={color} height={height} />
  }
  if (type === "travel") {
    return <IconTravel color={color} height={height} />
  }
  return undefined
}

const CareersPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    diversityAnod
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Join the team at Life Without Barriers`}
        description="Find a fulfilling career supporting people at an inclusive and diverse organisation."
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout theme={corporateThemeFull}>
        <Banner
          image={bannerImage}
          title="Careers"
          subTitle="Want a fulfilling career supporting people whilst being part of an
            inclusive workplace? We might be your next employer of choice."
          breadcrumbs={[routesObject.home]}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2} className="color-lwb-theme-medium">
                Partnering with people to change lives for the better
              </Heading>
              <p className="pt3 fw7">
                Our people and our values are the difference.
              </p>
              <p>
                The difference we want to make at Life without Barriers is to
                partner with people to change lives for the better. We can only
                achieve this by employing great people who understand the value
                of relationships with the people who trust us to provide their
                services. That’s why, we’re constantly on the lookout for people
                who are passionate; imaginative and flexible, courageous and
                responsive and value genuine, long-lasting relationships.
              </p>
              <p>
                We are specifically interested in creating more employment
                opportunities for people with disability – please get in touch
                or set up a{" "}
                <Link
                  to="https://careers.lwb.org.au/en/subscribe/"
                  className="black fw4"
                >
                  vacancy alert
                </Link>{" "}
                through our website
              </p>
              <p>
                Read more about our commitment to more inclusive workplaces for
                people with disability through our{" "}
                <Link
                  to="/social-policy/employment-without-barriers/"
                  className="black fw4"
                  rooted
                >
                  Employment Without Barriers Campaign
                </Link>
              </p>
              <div className="pt4">
                <SearchOpportunitiesButton />
              </div>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-50-l pb4 pb0-l">
                <YouTubeVideo
                  videoId="qFc-h5Ne5YU"
                  title="Careers at Life Without Barriers"
                  className="pt2 pr0 pr3-l"
                />
              </Box>
              <Box className="w-100 w-50-l pl0 pl4-l">
                <Heading size={2}>Our Collective</Heading>
                <p>
                  Our people are the soul of what we offer to people choosing
                  our services. Through an inclusive culture, underpinned by our
                  values, we want every member of our team to be engaged and
                  committed to provide meaningful outcomes for people who need
                  our support.
                </p>
                <p className="b">
                  We are looking for people who really care about people.
                </p>
                <p>
                  The people who trust us to provide their services come from
                  diverse cultural and social backgrounds with varied life
                  experiences and interests. In our organisation, our role is to
                  be guided by the people we support and help them achieve what
                  they want to from their life.
                </p>
                <p>
                  We also believe you will be at your best when your workplace
                  is inclusive and you can be your whole self at work, every
                  day.
                </p>
                <p className="b">
                  People with Disability and Aboriginal and Torres Strait
                  Islander people face too many barriers when seeking
                  employment.
                </p>
                <p>
                  Our{" "}
                  <Link
                    to="/social-policy/diversity-and-inclusion/accessibility-inclusion-employment-plan/"
                    className="black fw4"
                    rooted
                  >
                    Access Inclusion and Employment Plan
                  </Link>{" "}
                  and our{" "}
                  <Link
                    to="/social-policy/aboriginal-and-torres-strait-islander-peoples/our-reconciliation-action-plan/"
                    className="black fw4"
                    rooted
                  >
                    Reconciliation Action Plan
                  </Link>{" "}
                  both aim to increase our employment of people with disability
                  and Aboriginal and Torres Strait Islander people. Our
                  organisation is stronger and more productive when you are part
                  of our collective so please consider applying today.
                </p>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable">
              <Heading size={2} className="color-lwb-theme-medium">
                Humble company, big perks
              </Heading>
              <p>
                Our team is important to us – we want to ensure you feel valued
                and love working with us. Aside from working for a
                purpose-driven company striving to make a positive impact,
                you’ll also be able to access these great perks.
              </p>
            </Box>
            <Box>
              <IconGrid items={employeePerks} selectIcon={iconSelector} />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>Ok, where do I sign up?</Heading>
                <p>
                  Whether you’re a corporate star or your heart is in the
                  frontline, we want to hear from you! For a full list of our
                  current vacancies please search through our current
                  opportunities.
                </p>
                <div className="pt4">
                  <SearchOpportunitiesButton />
                </div>
                <div className="w-100 mv4">
                  <Panel background="bg-lwb-white">
                    <Row className="flex-wrap">
                      <div className="w-two-twelfths tc">
                        <IconWrapper
                          size="50"
                          color={corporateThemeFull.xxxlight}
                        >
                          <IconPin
                            height="25"
                            width="25"
                            color={corporateThemeFull.dark}
                          />
                        </IconWrapper>
                      </div>
                      <div className="w-ten-twelfths">
                        <p className="mt0 ph3 pl0-ns pr3-ns dib-ns">
                          <strong>Note: </strong>
                          To be considered for, and appointed to, a position at
                          Life Without Barriers, you must be fully vaccinated
                          against COVID-19 or hold a valid medical exemption
                          certificate. You will be required to provide evidence
                          of your vaccination status during the recruitment
                          process.
                        </p>
                      </div>
                    </Row>
                  </Panel>
                </div>
                <p className="">
                  If you are interested in our current job vacancies, general
                  career tips and tricks and learning more about our employees
                  follow us:
                </p>
                <p className="pt3">
                  <SocialMediaLinks styles={{ iconBackgroundColor: "#FFF" }} />
                </p>
              </Box>
              <Box className="w-100 w-third-l mt4 mt0-l">
                <Tile ribbon>
                  <div className="ma3 ma4-ns pv3-l">
                    <Heading
                      size={3}
                      topMargin="none"
                      className="color-lwb-theme-medium"
                    >
                      For any people with disability that require adjustments,
                      please contact:
                    </Heading>
                    <div className="pv3 pv2-l">
                      <ContactUsPhoneLink phoneNumber="0240334500" />
                      <ContactUsEmailLink
                        text="recruitment@lwb.org.au"
                        link={`mailto:recruitment@lwb.org.au`}
                      />
                    </div>
                  </div>
                </Tile>
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <NavigationTiles
                  heading={
                    <Heading size={2} className="color-lwb-theme-medium tc">
                      Learn why Life Without Barriers is a great place to work
                    </Heading>
                  }
                  currentPathname={location.pathname}
                  pages={[
                    OurValues,
                    OurAIEP,
                    OurLGBTIJourney,
                    DiversityAndInclusion,
                    ReconciliationActionPlanNoIcon,
                    WePutChildrenFirst
                  ]}
                />
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box>
              <div className="flex-ns flex-row justify-between">
                <div className="w-70 center w-30-m w-20-l">
                  <GatsbyImage
                    className="mh2 mh0-ns"
                    imgStyle={{ objectFit: "contain" }}
                    image={getGatsbyImage(diversityAnod)}
                    alt="Australian Network on Disability Logo"
                  />
                </div>
                <div className="w-100 w-75-ns ph3-m ph4-l">
                  <Heading size={3} topMargin="none">
                    We are a Disability Confident Recruiter
                  </Heading>
                  <p>
                    Life Without Barriers has recently been Awarded Disability
                    Confident Recruiter (DCR) status by the Australian Network
                    on Disability(AND). Obtaining DCR status shows that we are
                    an organisation that is committed to ensuring people with
                    disability are actively recruited in our organisation. This
                    is something we feel strongly about and hope to encourage
                    other employers to take the steps in making their
                    recruitment more inclusive. Find out how you can also become
                    a Disability Confident Recruiter at the AND website.
                  </p>
                  <ReadMoreLink
                    href="https://www.and.org.au/"
                    rooted
                    text="Learn more"
                    className="pt4"
                    external={true}
                  />
                </div>
              </div>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    bannerImage: file(relativePath: { regex: "/careers-banner-new.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    diversityAnod: file(
      relativePath: {
        regex: "/disability-confident-recruiter-2024-dark-red.png/"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default CareersPage
